import React from 'react'
import { Link } from 'gatsby'

import one from '/static/images/platform/china_to_global_feature_1.webp'
import two from '/static/images/platform/china_to_global_feature_2.webp'
import three from '/static/images/platform/china_to_global_feature_3.webp'
import { clickMessageBirdChat } from 'utils'

export const chinaGlobalFeatures = [
  {
    title: 'Same experience inside and outside of China',
    content: `Most Chinese companies struggle to provide the same performance and experience to its global users. We are here to help. Not only Mlytics has abundant PoPs inside and outside of China, but also we have the deepest know how of routing and interlinking these networks.`,
    imageSrc: one,
    imageAlt: 'Same experience inside and outside of China',
    children: (
      <button onClick={clickMessageBirdChat} className="bg-none h5-subtext-medium text-blue-100 underline my-3 w-fit">
        Learn more.
      </button>
    )
  },
  {
    title: 'No additional CAPEX investment',
    content: `When it comes to China-to-Global delivery, adding overseas infrastructure is never the solution, being able to route, manage and navigate through multiple networks is. We are here to make sure your money is well spent on the right thing.`,
    imageSrc: two,
    imageAlt: 'No additional CAPEX investment',
    children: (
      <button onClick={clickMessageBirdChat} className="bg-none h5-subtext-medium text-blue-100 underline my-3 w-fit">
        Learn more.
      </button>
    )
  },
  {
    title: 'Preserve your valuable origin resource',
    content: `Without proper orchestration, connecting your origin with global networks could cause additional load on your origin infra and drain its valuable resource. Mlytics’ has deepest knowledge of preserving your in-China origin’s resource while providing the best experience outside of China. `,
    imageSrc: three,
    imageAlt: 'Preserve your valuable origin resource',
    children: (
      <Link to="/contact-us" className="block h5-subtext-medium text-blue-100 underline my-3 w-fit">
        Chat with us.
      </Link>
    )
  }
]
