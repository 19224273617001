import React from 'react'
import { graphql } from 'gatsby'

import Layout from 'layouts/index'
import Seo from 'components/seo'
import Cover from 'components/common/cover'
import UseCase from 'components/common/useCase'
import SimpleSlider from 'components/carousel/simple'
import ContentCarousel from 'components/common/contentCarousel'
import toGlobalCover from '/static/images/platform/china_to_global_hero.webp'
import { chinaGlobalFeatures } from '/static/data/platform/china-global.static'
import { allCdnList } from '/static/data/cdnBrand.static'

const BRAND_SLIDER_SETTINGS = {
  infinite: true,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: true,
  pauseOnHover: false,
  speed: 9000,
  autoplaySpeed: 1000,
  cssEase: 'linear'
}

const ChinaToGlobal = () => {
  return (
    <Layout>
      <React.Fragment>
        <Seo
          seo={{
            metaTitle: `摩速科技 | 中国出海智能解决方案 一站式赋能海外业务`,
            metaDescription: `摩速科技为中国企业提供全方位出海智能解决方案。从海外加速到API接口平台，一站式科技赋能，助力企业提升海外业务效率，实现全球化布局。`,
            metaUrl: 'www.mlytics.com/platform/china-to-global',
            shareImage: {
              imageUrl: 'https://www.mlytics.com/wp-content/uploads/2023/03/og_v5.jpg',
              imageWidth: 1200,
              imageHeight: 630,
              imageType: 'image/jpeg'
            }
          }}
        />
        <section
          style={{
            height: 'calc(100vh - 100px)',
            minHeight: '600px'
          }}
          className="px-9 md:px-[86px] mb-5 lg:max-h-[800px] base:absolute base:left-1/2 base:-translate-x-1/2 base:w-screen base:max-w-[1920px]"
        >
          <Cover
            smallTitle="China to Global"
            title="Look no more. Here is the most practical and effective solution for your China-to-Global delivery"
            imgSrc={toGlobalCover}
            imgAlt="China to Global Hero Picture"
            paragraphContent="In China, network congestion and slower website speeds can significantly impact user experience. For many years, Mlytics has been the expert to interlink and route between China networks with Global networks."
            actionLinkOne="/contact-us"
            actionTextOne="Learn more"
          />
        </section>
        <div className="hidden mb-5 lg:max-h-[800px] base:block base:h-[calc(100vh-100px)] base:min-h-[600px]"></div>
        <section className="py-[60px] px-9 md:px-[86px]">
          <div className="w-full flex flex-col gap-10">
            <h2 className="h2-block-title text-black-100 text-center">Meshed China and Global Networks</h2>
            <div className="hidden py-5 md:block">
              <SimpleSlider className="slick-bg-white-gradient-x" settings={BRAND_SLIDER_SETTINGS}>
                {allCdnList.map((item) => (
                  <div key={item.alt}>
                    <img src={item.src} alt={item.alt} height="80" width="160" className="object-cover" />
                  </div>
                ))}
              </SimpleSlider>
            </div>
          </div>
        </section>
        <section className="py-[60px] px-9 md:px-[86px]">
          <ContentCarousel listItem={chinaGlobalFeatures} />
        </section>
        <section className="py-[60px]">
          <UseCase />
        </section>
      </React.Fragment>
    </Layout>
  )
}

export default ChinaToGlobal

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
